// External
import Box from '@taskrabbit/meadow-web/lib/Box';
import Container from '@taskrabbit/meadow-web/lib/Container';
import Image from 'next/image';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import Typography from '@taskrabbit/meadow-web/lib/Typography';

// Internal
import contentfulImageLoader from '~utils/contentfulImageLoader';

// Types
import type { HowItWorks as HowItWorksFields } from '~server/types/content';

const HowItWorksCopy = styled('div')(({ theme: { breakpoints, spacing } }) => ({
  alignItems: 'center',
  display: 'flex',

  [breakpoints.up('sm')]: {
    alignItems: 'flex-start',
  },
}));

const HowItWorksCopyContainer = styled('div')(
  ({
    theme: {
      breakpoints,
      meadow: {
        purpose: { borderRadius },
      },
      spacing,
    },
  }) => ({
    background: 'white',
    borderRadius: borderRadius.surface,
    display: 'flex',
    flexDirection: 'column',
    height: '315px',
    justifyContent: 'space-evenly',
    margin: spacing(2),
    padding: spacing(0, 5, 0, 2),
    position: 'static',
    zIndex: '1',

    [breakpoints.up('sm')]: {
      height: '449px',
      marginTop: '70px',
      padding: spacing(3),
      position: 'absolute',
      top: 0,
      width: '393px',
    },
  })
);

const HowItWorksStepBubble = styled(Box)(
  ({
    theme: {
      breakpoints,
      meadow: {
        purpose: { borderRadius },
      },
      spacing,
    },
  }) => ({
    alignItems: 'center',
    borderRadius: borderRadius.button,
    display: 'flex',
    height: '48px',
    justifyContent: 'center',
    marginRight: spacing(3),
    minHeight: '48px',
    minWidth: '48px',
    width: '48px',

    [breakpoints.up('sm')]: {
      height: '56px',
      minHeight: '56px',
      minWidth: '56px',
      width: '56px',
    },
  })
);

const DesktopImageContainer = styled(Box)(({ theme: { breakpoints } }) => ({
  display: 'none',

  [breakpoints.up('sm')]: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    marginLeft: '80px',
    maxHeight: '494px',
    maxWidth: '950px',
    position: 'relative',
    width: 'calc(100% - 80px)',
  },
}));

const SubContainer = styled('div')(
  ({
    theme: {
      breakpoints,
      meadow: {
        purpose: { borderRadius },
      },
      spacing,
    },
  }) => ({
    borderRadius: borderRadius.floating,
    display: 'flex',
    flexDirection: 'column',

    [breakpoints.up('sm')]: {
      maxWidth: '1110px',
      padding: spacing(6, 5),
      position: 'relative',
      width: '100%',
    },
  })
);

const HowItWorks = ({
  bgImage,
  header,
  text1,
  text2,
  text3,
}: Partial<HowItWorksFields>) => (
  <div data-testid="how-it-works">
    <Box
      sx={({ breakpoints }) => ({
        height: '228px',
        position: 'relative',

        [breakpoints.up('sm')]: { display: 'none' },
      })}
    >
      {bgImage?.bgImageUrl && (
        <Image
          alt={bgImage.bgImageText ?? ''}
          fill={true}
          loader={contentfulImageLoader}
          src={bgImage.bgImageUrl}
          style={{ objectFit: 'cover', objectPosition: 'center' }}
        />
      )}
    </Box>

    <Container
      disableGutters={true}
      maxWidth={false}
      sx={({
        breakpoints,
        meadow: {
          purpose: { marketing },
        },
      }) => ({
        backgroundColor: marketing.background.primary.light,
        height: '347px',
        position: 'relative',

        [breakpoints.up('sm')]: {
          display: 'flex',
          height: '590px',
          justifyContent: 'center',
        },
      })}
    >
      <SubContainer>
        <DesktopImageContainer>
          {bgImage?.bgImageUrl && (
            <Image
              alt={bgImage.bgImageText ?? ''}
              data-testid="how-it-works-bg-image"
              fill={true}
              loader={contentfulImageLoader}
              src={bgImage.bgImageUrl}
              style={{ borderRadius: 8, objectFit: 'cover' }}
            />
          )}
        </DesktopImageContainer>
        <HowItWorksCopyContainer>
          <Typography
            color="impact.main"
            id="how-it-works-anchor"
            sx={{ mt: { md: -11, xs: -18 }, pt: { md: 11, xs: 18 } }}
            variant="h3"
          >
            {header}
          </Typography>

          <HowItWorksCopy key={text1}>
            <HowItWorksStepBubble
              sx={({ meadow: { purpose } }) => ({
                backgroundColor: purpose.secondaryAccent.extraSubtle,
              })}
            >
              <Typography color="impact.main" variant="h3">
                1
              </Typography>
            </HowItWorksStepBubble>
            <Typography color="impact.main" variant="body1">
              {text1}
            </Typography>
          </HowItWorksCopy>

          <HowItWorksCopy key={text2}>
            <HowItWorksStepBubble
              sx={({ meadow: { purpose } }) => ({
                backgroundColor: purpose.marketing.background.primary.light,
              })}
            >
              <Typography color="impact.main" variant="h3">
                2
              </Typography>
            </HowItWorksStepBubble>
            <Typography color="impact.main" variant="body1">
              {text2}
            </Typography>
          </HowItWorksCopy>

          <HowItWorksCopy key={text3}>
            <HowItWorksStepBubble
              sx={({ meadow: { purpose } }) => ({
                backgroundColor: purpose.primary.extraSubtle,
              })}
            >
              <Typography color="impact.main" variant="h3">
                3
              </Typography>
            </HowItWorksStepBubble>
            <Typography color="impact.main" variant="body1">
              {text3}
            </Typography>
          </HowItWorksCopy>
        </HowItWorksCopyContainer>
      </SubContainer>
    </Container>
  </div>
);

export default HowItWorks;
