// External
import Image from 'next/image';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';

// Components
/* We want this to take the whole size of its parent so its own children can play
around with top, right, bottom and left with ease */
export const BigContainer = styled('span')`
  bottom: 0;
  display: block;
  left: 0;
  pointer-events: none;
  right: 0;
  top: 0;
`;

export const StyledShape = styled(Image)`
  display: none;
  position: absolute;
  user-select: none;
  z-index: 0;
`;

export const BackgroundShapeDesktop = styled(StyledShape)`
  @media (min-width: 1440px) {
    display: block;
  }
`;

export const BackgroundShapeTabletLand = styled(StyledShape)`
  @media (min-width: 900px) and (max-width: 1439px) {
    display: block;
  }
`;

export const BackgroundShapeTabletPort = styled(StyledShape)`
  @media (min-width: 600px) and (max-width: 899px) {
    display: block;
  }
`;

export const BackgroundShapeMobile = styled(StyledShape)`
  @media (max-width: 599px) {
    display: block;
  }
`;
