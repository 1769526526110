// External
import Grid from '@taskrabbit/meadow-web/lib/Grid';
import Image from 'next/image';
import Link from 'next/link';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import Typography from '@taskrabbit/meadow-web/lib/Typography';

// Internal
import {
  StyledContainer,
  StyledSection,
} from '~components/marketing/index.styled';
import contentfulImageLoader from '~utils/contentfulImageLoader';
import RichText from './RichText';
import {
  BackgroundShapeMobile,
  BackgroundShapeTabletPort,
  BigContainer,
} from '~components/pages/nearMe/BackgroundShapes';
import shapeTabPort from '~public/next-images/background_shapes/nearMe/protection_satisfaction_tablet_port.svg';
import shapeMobile from '~public/next-images/background_shapes/nearMe/protection_satisfaction_mobile.svg';

// Types
import type { Satisfaction } from '~server/types/content';

// Styled Components
const StyledImageWrapper = styled('div')(() => ({
  marginTop: '24px',
  minHeight: '48px',
  position: 'relative',
  width: '225px',
}));

const StyledSatisfactionHeader = styled(Typography)(
  ({
    theme: {
      breakpoints,
      meadow: { purpose },
      spacing,
    },
  }) => ({
    alignSelf: 'start',
    color: purpose.impact.emphasis,
    marginBottom: spacing(5),

    [breakpoints.up('md')]: {
      marginBottom: spacing(6),
    },
  })
);

const StyledSatisfactionColoredHeader = styled(Typography)(
  ({
    theme: {
      meadow: { purpose },
    },
  }) => ({
    color: purpose.secondaryAccent.main,
  })
);

const StyledGrid = styled(Grid)(({ theme: { breakpoints, spacing } }) => ({
  alignSelf: 'start',
  maxWidth: '346px',
  paddingRight: spacing(8),

  [breakpoints.up('sm')]: {
    paddingRight: 0,
  },

  [breakpoints.up('md')]: {
    maxWidth: '100%',
  },
}));

const ProtectionSatisfactionContainer = styled(StyledContainer)(
  ({
    theme: {
      breakpoints,
      meadow: { purpose },
      spacing,
    },
  }) => ({
    backgroundColor: purpose.background.emphasis,
    position: 'relative',

    [breakpoints.up('lg')]: {
      paddingBottom: spacing(2),
      paddingTop: spacing(2),
    },
  })
);

// Main Component
const ProtectionSatisfactionProof = ({
  header1,
  header2,
  guarantee,
  imageLinkUrl,
  imageText,
  imageUrl,
  hasBackgroundShape,
}: Partial<Satisfaction> & {
  hasBackgroundShape?: boolean;
}) => (
  <ProtectionSatisfactionContainer>
    <BackgroundShapes hasBackgroundShape={hasBackgroundShape} />
    <StyledSection>
      <StyledSatisfactionHeader variant="h1">
        {header1}
        <StyledSatisfactionColoredHeader component="span" variant="h1">
          &nbsp;{header2}
        </StyledSatisfactionColoredHeader>
      </StyledSatisfactionHeader>

      <StyledGrid
        columnSpacing={{ lg: 10, sm: 8 }}
        container
        rowSpacing={{ sm: 7, xs: 6 }}
      >
        {guarantee?.map(({ header, richText }, index) => (
          <Grid item key={header} lg={4} md={6} sm={12} xs={12}>
            <Typography
              color="impact.main"
              component="h3"
              sx={{ mb: 2 }}
              variant="subtitle1"
            >
              {header}
            </Typography>

            <RichText richText={richText} />

            {/* Must check index such that image only appears on first entry */}
            {imageUrl && index === 0 && imageLinkUrl && (
              <StyledImageWrapper>
                <Link href={imageLinkUrl}>
                  <Image
                    alt={imageText ?? ''}
                    fill={true}
                    loader={contentfulImageLoader}
                    sizes="225px"
                    src={imageUrl}
                    style={{
                      cursor: 'pointer',
                      objectFit: 'contain',
                      objectPosition: 'center',
                    }}
                  />
                </Link>
              </StyledImageWrapper>
            )}
          </Grid>
        ))}
      </StyledGrid>
    </StyledSection>
  </ProtectionSatisfactionContainer>
);

const BackgroundShapes = ({
  hasBackgroundShape,
}: {
  hasBackgroundShape?: boolean;
}) => {
  if (!hasBackgroundShape) {
    return null;
  }
  return (
    <BigContainer>
      <BackgroundShapeTabletPort
        alt=""
        priority={true}
        src={shapeTabPort}
        sx={{
          right: 0,
          top: '-250px',
        }}
      />
      <BackgroundShapeMobile
        alt=""
        priority={true}
        src={shapeMobile}
        sx={{
          right: 0,
          top: '20px',
        }}
      />
    </BigContainer>
  );
};

export default ProtectionSatisfactionProof;
